import { Component } from "@angular/core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "loading-spinner",
    template: `
  <div class="text-center my-4">
    <fa-icon [icon]="faSpinner" [animation]="'spin'"></fa-icon>
  </div>
  `,
    imports: [FaIconComponent]
})
export class LoadingSpinnerComponent {
    public faSpinner = faSpinner;
}
