import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbAlertModule, NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingSpinnerComponent } from "./components/loading-spinner/loading-spinner";
import { ToastrModule } from "ngx-toastr";



@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        NgbModule,
        NgbAlertModule,
        NgbTooltipModule,
        ToastrModule.forRoot(),
        LoadingSpinnerComponent
    ],
    exports: [
        FontAwesomeModule,
        LoadingSpinnerComponent,
        NgbModule,
        NgbAlertModule,
        NgbTooltipModule
    ]
})
export class SharedModule { }
