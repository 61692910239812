
import { Component, Injectable, OnInit, output } from "@angular/core";
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from "@angular/forms";
import { Observable, of } from "rxjs";
import { NgbActiveModal, NgbDateStruct, NgbModal, NgbDate, NgbDateParserFormatter, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";


import { Subscription } from "rxjs";
import { faCalendar, faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { MessageItem, MessageService } from "@core/services/message.service";
import { UserInfoService } from "@palliativ-core/services/user-info.service";
import { DataService } from "@core/services/data.service";
import { ToastrService } from "ngx-toastr";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";

@Component({
    selector: "app-edit-message-modal",
    templateUrl: "./edit-message-modal.component.html",
    styleUrls: ["./edit-message-modal.component.scss"],
    imports: [NgIf, ReactiveFormsModule, NgFor, NgbInputDatepicker, FaIconComponent, AsyncPipe]
})

export class EditMessageModalComponent implements OnInit {
    //Ikoner
    faCalendar = faCalendar;
    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;

    readonly messageDeleted = output<boolean>();
    id = 0;
    $subscriptions: Subscription[] = [];
    interval = 0;
    errorMessage = "";
    styleTypes: Observable<any[]> = null!;
    message: MessageItem | undefined;
    userId = 0;
    fullName = "";

    fromDateminDate!: NgbDateStruct;
    fromDatemaxDate!: NgbDateStruct;
    toDateminDate!: NgbDateStruct;
    toDatemaxDate!: NgbDateStruct;
    //@Output() itemChanged = new EventEmitter<ChangeRequestsApplication>();

    private validatorNotZero = [
        Validators.required,
        Validators.pattern(/^(?![0]\d*)/)
    ];

    public get editForm(): FormGroup { return this._editForm; }

    private _editForm!: FormGroup;

    constructor(
        private activeModal: NgbActiveModal,
        private messageService: MessageService,
        private userInfo: UserInfoService,
        public formatter: NgbDateParserFormatter,
        private dataService: DataService,
        private toastr: ToastrService
    ) { }

    previewMessageItem: MessageItem = {} as MessageItem;
    //////////
    ///
    ngOnInit(): void {

        this.userInfo.getUserInfo().subscribe(userInfo => {
            this.userId = userInfo.id;
            this.fullName = userInfo.name;
        });

        this.styleTypes = this.dataService.getInfTable("AlertStyle");
        this._editForm = new FormGroup({
            header: new FormControl("", [Validators.maxLength(100), Validators.required]),
            description: new FormControl("", Validators.required),
            link: new FormControl("", Validators.maxLength(500)),
            type: new FormControl("0", this.validatorNotZero),
            fromDate: new FormControl(),
            toDate: new FormControl()
        });

        if (this.id > 0) {
            this.messageService.getMessage(this.id).subscribe(_ => {
                this.message = _;
                this.editForm.patchValue({
                    header: _.header,
                    description: _.description,
                    link: _.link,
                    type: _.type,
                    fromDate: this.formatBack(_.fromDate ?? undefined),
                    toDate: this.formatBack(_.toDate ?? undefined)
                });
            });
        }
    }
    //////////
    ///
    fromDateChanged() {
        const selectedDate = this.editForm.get("fromDate")?.value;
        this.toDateminDate = selectedDate;
    }
    //////////
    //
    toDateChanged() {
        const selectedDate = this.editForm.get("toDate")?.value;
        this.fromDatemaxDate = selectedDate;
    }
    //////////
    ///
    ngOnDestroy(): void {
        this.$subscriptions.forEach(_ => _.unsubscribe());
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
    //////////
    ///
    public onSubmit(): void {
        //console.log(this.editForm.get("principal")?.value);
        const message = {
            id: this.id,
            header: this.editForm.get("header")?.value ?? "",
            description: this.editForm.get("description")?.value ?? "",
            //hsaId: this._editForm.get("hsaid")?.value ?? "",
            link: this.editForm.get("link")?.value ?? "",
            type: this.editForm.get("type")?.value ?? 0,
            fromDate: this.format(this.editForm.get("fromDate")?.value ?? null) ?? null,
            toDate: this.format(this.editForm.get("toDate")?.value ?? null) ?? null,
            // Om det är ett befintligt meddelande som redigeras ska användaren som skapade meddelandet står kvar som skapare, annars är det den inloggade användaren som står som skapare.
            createdById: (this.message?.createdById ? this.message?.createdById : this.userId).toString(),
            changedById: this.userId.toString()
        } as MessageItem;

        console.log(message);
            this.messageService.saveMessage(this.id, message).subscribe({
                next: () => {
                    this.activeModal.close();
                    this.toastr.success("Meddelandet är sparat.");
                },
                error: (errorResponse: any) => {
                    this.toastr.error(errorResponse.error);
                }
            });
    }
    //////////
    ///
    public onCancel(): void {
        this.activeModal.dismiss();
    }
    //////////
    ///
    private format(date: NgbDateStruct): Date | null {
        return date ? new Date(Date.UTC(date.year, date.month - 1, date.day)) : null;
    }
    //////////
    ///
    private formatBack(date?: any): NgbDateStruct | null {
        let d = new Date(date);
        return date ? new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate()) : null;
    }
    //////////
    ///
    deleteMessage() {
        this.messageService.deleteMessage(this.id).subscribe({
            next: (_: any) => {
                this.messageDeleted.emit(true);
                this.toastr.success("Meddelandet är raderat.");
                this.activeModal.close();
            },
            error: (errorResponse: any) => {
                this.errorMessage = errorResponse.error;
            }
        });
    }
    showPreview = false;
    //////////
    ///
    changeHeader() { this.previewMessageItem.header = this.editForm.get("header")?.value }
    changeDesc() { this.previewMessageItem.description = this.editForm.get("description")?.value }
    changeType() {
        this.previewMessageItem.type = this.editForm.get("type")?.value;
        this.previewMessageItem.styleClass = this.messageService.messageStyleType.find(_ => this.editForm.get("type")?.value == _.id)?.styleClass;
        this.previewMessageItem.styleIcon = this.messageService.messageStyleType.find(_ => this.editForm.get("type")?.value == _.id)?.icon;
    }
    changeLink() { this.previewMessageItem.link = this.editForm.get("link")?.value }
    preViewMessage() {
        this.showPreview = !this.showPreview;
        this.previewMessageItem = {
            header: this.editForm.get("header")?.value,
            description: this.editForm.get("description")?.value,
            link: this.editForm.get("link")?.value,
            type: this.editForm.get("type")?.value,
            createdByName: this.fullName,
            styleClass: this.messageService.messageStyleType.find(_ => this.editForm.get("type")?.value == _.id)?.styleClass,
            styleIcon: this.messageService.messageStyleType.find(_ => this.editForm.get("type")?.value == _.id)?.icon,
        } as MessageItem;
    }
}


@Injectable({
    providedIn: "any"
})

export class EditMessageModalService {
    constructor(private modalService: NgbModal) { }

    public show(id: number): Observable<unknown> {
        const modalRef = this.modalService.open(EditMessageModalComponent, { backdrop: "static", size: "lg" });
        modalRef.componentInstance.id = id;

        return modalRef.closed;
    }
}
