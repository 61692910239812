import { InjectionToken } from "@angular/core";
import { OpenIdConfiguration } from "angular-auth-oidc-client";


export class AppSettings {
    public production = false;
    public baseHref = "";
    public apiUrl: {
        base: string,
        paths: { [key: string]: string }
    } = { base: "", paths: {} };
    public links: { [key: string]: string } = {};
    public authConfig: OpenIdConfiguration = {
        autoUserInfo: true,
        silentRenew: true,
        useRefreshToken: true,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        disablePkce: false,
        ignoreNonceAfterRefresh: true
    };
    public info = new ApplicationInfo();

    protected queryParams2String(queryString?: any): string {
        if (!queryString)
            return "";

        let q = "?";
        for (const key in queryString) {
            if (q.length > 1)
                q += "&";
            q += key + "=" + queryString[key];
        }
        return q;
    }

    public api(path: string, queryParams?: any): string {
        if (path.startsWith("/"))
            path = path.substring(1);

        return this.baseHref + "api/" + path + this.queryParams2String(queryParams);
    }


    public api2(app: string, path: string, queryParams?: any): string {
        var p = this.apiUrl.paths[app];
        if (p == null)
            return "";

        var pp = [this.apiUrl.base, p, path]
            .map(s => s.replace(/^\/+|\/+$/g, ""))
            .join("/");

        return pp + this.queryParams2String(queryParams);
    }
}


export class ApplicationInfo {
    public product = "";
    public version = "";
    public fileVersion = "";
    public copyright = "";
}



export const APP_SETTINGS = new InjectionToken<AppSettings>("appsettings");
